import gql from "graphql-tag";

export const CHANGE_ORDER_LESSON_ATTACHMENT_MUTATION = gql`
  mutation ChangeOrderLessonAttachment(
    $first: ChangeOrderLessonAttachmentInput!
    $second: ChangeOrderLessonAttachmentInput!
  ) {
    changeOrderLessonAttachment(first: $first, second: $second) {
      id
      fullUri
      title
      mimeType
      order
    }
  }
`;
