import { handleGqlResponseBody } from "@/utils/apollo.util";
import { toSnakeCase } from "@/utils/string";
import { GET_QUIZZES_QUERY } from "@/graphql/queries/quiz/get-quizzes.query";
import { CREATE_QUIZ_MUTATION } from "@/graphql/mutations/quiz/create.mutation";
import { UPDATE_QUIZ_MUTATION } from "@/graphql/mutations/quiz/update.mutation";
import { DELETE_QUIZ_MUTATION } from "@/graphql/mutations/quiz/delete.mutation";
import { GET_QUIZ_QUERY } from "@/graphql/queries/quiz/get-quiz.query";
import { GET_LESSON_QUIZZES_QUERY } from "@/graphql/queries/quiz/get-lesson-quizzes.query";
import { UNLINK_QUIZ_MUTATION } from "@/graphql/mutations/quiz/unlink-quiz.mutation";
import { LINK_QUIZ_MUTATION } from "@/graphql/mutations/quiz/link-quiz.mutation";
import { CHECK_QUIZ_EXERCISES_MUTATION } from "@/graphql/mutations/quiz/check-quiz.mutation";
import { GET_QUIZ_HISTORY_PAGINATED_QUERY } from "@/graphql/queries/quiz/get-quiz-history-paginated.query";
import { GET_QUIZ_HISTORY_QUERY } from "@/graphql/queries/quiz/get-quiz-history.query";

const quiz = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getQuizQuery(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_QUIZ_QUERY,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async createQuiz(
      _,
      {
        title,
        description,
        privacy,
        isVocabulary,
        itemsPerPage,
        showCorrectnessOnError,
        countPartialPts,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_QUIZ_MUTATION,
          variables: {
            title,
            description,
            privacy,
            isVocabulary,
            itemsPerPage,
            showCorrectnessOnError,
            countPartialPts,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async updateQuiz(
      _,
      {
        id,
        title,
        description,
        privacy,
        itemsPerPage,
        showCorrectnessOnError,
        countPartialPts,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UPDATE_QUIZ_MUTATION,
          variables: {
            id,
            title,
            description,
            privacy,
            itemsPerPage,
            showCorrectnessOnError,
            countPartialPts,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async deleteQuiz(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: DELETE_QUIZ_MUTATION,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async linkQuiz(_, { lessonId, quizId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: LINK_QUIZ_MUTATION,
          variables: { lessonId, quizId },
          fetchPolicy: "no-cache",
        });
      });
    },
    async unlinkQuiz(_, { quizId, lessonId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UNLINK_QUIZ_MUTATION,
          variables: { quizId, lessonId },
          fetchPolicy: "no-cache",
        });
      });
    },
    async checkQuiz(_, { data, quizId, nonRegisteredData }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CHECK_QUIZ_EXERCISES_MUTATION,
          variables: {
            data,
            quizId,
            nonRegisteredData,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getQuizzes(
      _,
      {
        skip,
        take,
        orderBy,
        search,
        privacy,
        type,
        isVocabulary,
        exceptLinked,
        lessonId,
        userId,
      }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_QUIZZES_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            search,
            privacy,
            type,
            isVocabulary,
            exceptLinked,
            lessonId,
            userId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getLessonQuizzes(
      _,
      { skip, take, orderBy, search, privacy, lessonId, isVocabulary }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_LESSON_QUIZZES_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            search,
            privacy,
            lessonId,
            isVocabulary,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getQuizHistoryPaginated(
      _,
      { skip, take, quizId, userId, registered, lessonScheduleId }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_QUIZ_HISTORY_PAGINATED_QUERY,
          variables: {
            skip,
            take,
            quizId,
            userId,
            registered,
            lessonScheduleId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getQuizHistory(_, { quizHistoryId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_QUIZ_HISTORY_QUERY,
          variables: {
            quizHistoryId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default quiz;
