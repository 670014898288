import gql from "graphql-tag";

export const UPDATE_LESSON_ATTACHMENT_MUTATION = gql`
  mutation UpdateLessonAttachment($id: Int!, $title: String!) {
    updateLessonAttachment(data: { id: $id, title: $title }) {
      id
      fullUri
      title
      mimeType
      order
    }
  }
`;
