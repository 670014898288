import gql from "graphql-tag";

export const CREATE_QUIZ_MUTATION = gql`
  mutation CreateQuiz(
    $description: String!
    $privacy: LessonQuizPrivacy!
    $title: String!
    $itemsPerPage: Int
    $showCorrectnessOnError: Boolean
    $countPartialPts: Boolean
    $isVocabulary: Boolean
  ) {
    createQuiz(
      data: {
        description: $description
        privacy: $privacy
        title: $title
        itemsPerPage: $itemsPerPage
        showCorrectnessOnError: $showCorrectnessOnError
        countPartialPts: $countPartialPts
        isVocabulary: $isVocabulary
      }
    ) {
      id
      title
      description
      privacy
      createdAt
      updatedAt
      isVocabulary
      itemsPerPage
      showCorrectnessOnError
      countPartialPts
      authorId
      fork {
        id
        userId
      }
    }
  }
`;
