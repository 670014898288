import gql from "graphql-tag";

export const GET_USER_MATCH_PAGINATION_QUERY = gql`
  query GetUsersMatchPagination(
    $teachId: Int!
    $query: String
    $priceFrom: Float
    $priceTo: Float
    $criteriaIds: [Int!]
    $interestIds: [Int!]
    $textbookIds: [Int!]
    $speakIds: [Int!]
    $specialityIds: [Int!]
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getUsersMatch(
      data: {
        query: $query
        priceFrom: $priceFrom
        priceTo: $priceTo
        speakIds: $speakIds
        criteriaIds: $criteriaIds
        interestIds: $interestIds
        specialityIds: $specialityIds
        textbookIds: $textbookIds
        teachId: $teachId
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      hasMore
      total
      items {
        id
        firstName
        lastName
        shortAbout
        longAbout
        profilePhotoX128 {
          fullUri
        }
        interests {
          id
          icon
          title
        }
        speaks {
          id
          icon
          title
        }
        specialities {
          id
          title
        }
        userStudentsComputed {
          number
        }
        userLessonsComputed {
          number
        }
        commonRatingComputed {
          rating
          number
        }
        userLastReviewItems {
          value
          reviewQuestion {
            id
            title
          }
          reviewer {
            id
            firstName
            lastName
            profilePhotoX64 {
              fullUri
            }
          }
        }
        userLastReviewByQuestionItems {
          value
          reviewQuestion {
            id
            title
          }
          reviewer {
            id
            firstName
            lastName
            profilePhotoX64 {
              fullUri
            }
          }
        }
        reviewQuestionRatingComputed {
          rating
          number
          reviewQuestion {
            id
            title
          }
        }
        priceRangeComputed {
          from
          to
        }
      }
    }
  }
`;
