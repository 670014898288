import { loadingByKeyLaterInitMixin } from "@/mixins/loading-by-key-later-init.mixin";
import { errorByKeyLaterInitMixin } from "@/mixins/error-by-key-later-init.mixin";

export const paginationByKeyLaterMixin = {
  mixins: [loadingByKeyLaterInitMixin, errorByKeyLaterInitMixin],
  data() {
    return {
      paginationContextByKey: {},
    };
  },
  destroyed() {},
  computed: {
    getPaginationContext() {
      return (key) => {
        if (key in this.paginationContextByKey)
          return this.paginationContextByKey[key];
        return null;
      };
    },
  },
  methods: {
    initPaginationByKey(
      key,
      query,
      filters,
      requiredFilters = [],
      options = {
        itemsPerPage: 20,
      }
    ) {
      this.initLoadingByKey(key);
      this.initErrorByKey(key);

      this.$set(this.paginationContextByKey, key, {
        query,
        requiredFilters,
        frozen: false,
        items: [],
        total: 0,
        pages: 0,
        hasMore: false,
        extraData: null,
        options: { filters, page: 1, ...options, sortBy: [] },
        requiredFiltersValid: function () {
          console.log(
            "requiredFiltersValid",
            key,
            this.frozen,
            requiredFilters
          );
          if (this.frozen) return false;
          if (!requiredFilters.length) return true;

          return requiredFilters.every((fieldName) => {
            if (typeof fieldName === "function") {
              return fieldName(this.options.filters);
            }

            return this.options.filters[fieldName] !== null;
          });
        },
        // eslint-disable-next-line no-unused-vars
        onChangeItems: (items) => {},
        changeItemsPerPage: function (value) {
          this.options.itemsPerPage = value;
        },
        firstLoad: false,
        loadPage: async () => {
          console.log("trying to load page", key);
          const context = this.paginationContextByKey[key];
          if (!context.requiredFiltersValid()) return;

          console.log("trying to load page2", key);
          // console.log("loading", key);
          this.setLoadingByKey(key, true);
          await this.$showGqlError(
            async () => {
              const orderBy = this.paginationContextByKey[key].options.sortBy
                ?.length
                ? this.paginationContextByKey[key].options.sortBy.map(
                    (fieldName, index) => {
                      return {
                        direction: this.paginationContextByKey[key].options
                          ?.sortDesc[index]
                          ? "Desc"
                          : "Asc",
                        fieldName,
                        nulls: "Last",
                      };
                    }
                  )
                : [];

              const paginationResult = await this.$store.dispatch(query, {
                skip:
                  this.paginationContextByKey[key].options.page *
                    this.paginationContextByKey[key].options.itemsPerPage -
                  this.paginationContextByKey[key].options.itemsPerPage,
                take: this.paginationContextByKey[key].options.itemsPerPage,
                orderBy,
                ...this.paginationContextByKey[key].options.filters,
              });

              if (options.appendToItems) {
                if (options.reversedItems) {
                  this.paginationContextByKey[key].items.push(
                    ...paginationResult.items.reverse()
                  );
                } else {
                  this.paginationContextByKey[key].items.push(
                    ...paginationResult.items
                  );
                }
              } else if (options.prependToItems) {
                if (options.reversedItems) {
                  this.paginationContextByKey[key].items.unshift(
                    ...paginationResult.items.reverse()
                  );
                } else {
                  this.paginationContextByKey[key].items.unshift(
                    ...paginationResult.items
                  );
                }
              } else {
                this.paginationContextByKey[key].items = paginationResult.items;
              }

              setTimeout(() => {
                this.paginationContextByKey[key].onChangeItems(
                  this.paginationContextByKey[key].items
                );
              });

              this.paginationContextByKey[key].firstLoad = true;

              // console.log(
              //   "items changed",
              //   key,
              //   this.paginationContextByKey[key].items.length
              // );

              this.paginationContextByKey[key].pages = Math.ceil(
                paginationResult.total / options.itemsPerPage
              );
              this.paginationContextByKey[key].total = paginationResult.total;
              this.paginationContextByKey[key].hasMore =
                paginationResult.hasMore;
              this.paginationContextByKey[key].extraData =
                paginationResult.extraData;

              this.setLoadingByKey(key, false);
              this.clearErrorsByKey(key);
            },
            (_, { handledErrors }) => {
              this.setErrorsByKey(key, handledErrors);
              this.setLoadingByKey(key, false);
            },
            {},
            true
          );
        },
        updatePage: async function (page) {
          // console.log("updatePage", key, page);
          if (page === this.options.page) return;
          this.options.page = page;
          await this.loadPage();
        },
        loadMore: async function () {
          this.options.page++;
          await this.loadPage();
        },
        resetPage: async function () {
          // console.log("resetPage", key);
          this.items = [];
          this.options.page = 1;
          await this.loadPage();
        },
        clearPage: async function () {
          // console.log("resetPage", key);
          this.items = [];
          this.options.page = 1;
        },
        onFilterChange: async function (key, value) {
          // console.log("onFilterChange", key, value);
          this.options.page = 1;
          this.options.filters[key] = value;
        },
        onFiltersChange: async function (keyValues) {
          this.frozen = true;
          for (const [key, value] of Object.entries(keyValues)) {
            this.options.filters[key] = value;
          }

          this.frozen = false;
          this.options.page = 1;
          await this.loadPage();
        },
      });

      setTimeout(() => {
        this.$watch(
          `paginationContextByKey.${key}.options.filters`,
          async () => {
            console.log(
              "filter change watcher",
              key,
              this.paginationContextByKey[key].options
            );
            const paginationContext = this.paginationContextByKey[key];
            if (!paginationContext) return;

            await paginationContext.clearPage();

            if (!paginationContext.requiredFiltersValid()) return;
            await paginationContext.resetPage();

            if (options.onFiltersChange)
              options.onFiltersChange(paginationContext.options.filters);
          },
          {
            deep: true,
          }
        );
      });

      this.paginationContextByKey[key].loadPage();
    },
    destroyPaginationByKey(key) {
      this.$set(this.paginationContextByKey, key, null);
    },
  },
};
