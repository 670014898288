import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_USER_NOTIFICATION_PAGINATION_QUERY } from "@/graphql/queries/user/notification/get-user-notification-pagination.query";
import { SET_USER_NOTIFICATIONS_SEEN } from "@/graphql/mutations/user/notification/set-user-notifications-seen.mutation";
import { GET_USER_BADGES_QUERY } from "@/graphql/queries/user/notification/get-user-badges.query";

const notification = {
  state: {
    newMessageAudio: new Audio(require("@/assets/audio/new-message.wav")),
    readMessageAudio: new Audio(require("@/assets/audio/read-message.wav")),
    newNotificationAudio: new Audio(
      require("@/assets/audio/new-notification.mp3")
    ),
    newConnectionAudio: new Audio(require("@/assets/audio/new-connection.wav")),
    seenNotificationIds: [],
    urgentNotifications: [],
    messagesCount: null,
    connectionsCount: null,
    notificationBadges: null,
  },
  getters: {
    getSeenNotificationIds(state) {
      return state.seenNotificationIds;
    },
    getNotificationComputedBadges(state) {
      if (!state.badges) return { null: 0 };

      return state.badges.reduce(
        (accumulator, currentValue) => {
          accumulator.null += currentValue.counts;
          accumulator[currentValue.type] = currentValue.counts;

          return accumulator;
        },
        { null: 0 }
      );
    },
    getNotificationsCount(state) {
      return state.notificationsCount;
    },
    getMessagesCount(state) {
      return state.messagesCount;
    },
    getConnectionsCount(state) {
      return state.connectionsCount;
    },
    getUrgentNotifications(state) {
      return state.urgentNotifications;
    },
  },
  mutations: {},
  actions: {
    async playNewNotificationSound({ state }) {
      await state.newNotificationAudio.play().catch(() => {
        window.addEventListener(
          "click",
          async () => {
            await state.newNotificationAudio.play();
          },
          { once: true }
        );
      });
    },
    async playNewMessageSound({ state }) {
      await state.newMessageAudio.play().catch(() => {
        window.addEventListener(
          "click",
          async () => {
            await state.newConnectionAudio.play();
          },
          { once: true }
        );
      });
    },
    async playReadMessageSound({ state }) {
      await state.readMessageAudio.play().catch(() => {
        window.addEventListener(
          "click",
          async () => {
            await state.readMessageAudio.play();
          },
          { once: true }
        );
      });
    },
    async playNewConnectionSound({ state }) {
      await state.newConnectionAudio.play().catch(() => {
        window.addEventListener(
          "click",
          async () => {
            await state.newConnectionAudio.play();
          },
          { once: true }
        );
      });
    },
    async sendSeenNotificationIds({ state }) {
      if (!state.seenNotificationIds.length) return;

      const idsToSend = state.seenNotificationIds.splice(
        0,
        state.seenNotificationIds.length
      );
      try {
        await handleGqlResponseBody((apollo) => {
          return apollo.mutate({
            mutation: SET_USER_NOTIFICATIONS_SEEN,
            fetchPolicy: "no-cache",
            variables: {
              ids: idsToSend,
            },
          });
        });
      } catch (e) {
        state.seenNotificationIds.push(...idsToSend);
      }
    },
    async setNotificationSeen({ state }, { id }) {
      ``;
      if (state.seenNotificationIds.includes(id)) return;
      state.seenNotificationIds.push(id);
    },
    async updateNotificationCountQuery({ state }) {
      console.log("updateNotificationCountQuery");
      const {
        notificationBadges,
        messagesCount,
        connectionsCount,
        urgentNotifications,
      } = await handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_BADGES_QUERY,
          fetchPolicy: "no-cache",
        });
      });

      // console.log("updateNotificationBadgesQuery", response);

      state.messagesCount = messagesCount;
      state.connectionsCount = connectionsCount;
      state.notificationBadges = notificationBadges;
      state.urgentNotifications = urgentNotifications;
    },
    async getUserNotificationsQuery(_, { skip, take, type }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_NOTIFICATION_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            type,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default notification;
