import gql from "graphql-tag";

export const GET_LESSONS_PAGINATION_QUERY = gql`
  query GetLessonsPagination(
    $type: LessonType!
    $orderBy: [OrderByInput!]
    $skip: Int!
    $take: Int!
    $privacy: LessonQuizPrivacy
    $search: String
    $userId: Int
  ) {
    getLessons(
      data: {
        orderBy: $orderBy
        pagination: { skip: $skip, take: $take }
        privacy: $privacy
        search: $search
        type: $type
        userId: $userId
      }
    ) {
      hasMore
      total
      items {
        id
        title
        description
        privacy
        createdAt
        authorId
        author {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        fork {
          id
          userId
        }
      }
    }
  }
`;
