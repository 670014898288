import gql from "graphql-tag";

export const GET_LESSON_QUERY = gql`
  query GetLessonQuery($id: Int!) {
    getLesson(data: { id: $id }) {
      id
      title
      authorId
      description
      privacy
      createdAt
      notes
      attachments {
        id
        fullUri
        title
        mimeType
        order
      }
      fork {
        id
        userId
      }
    }
  }
`;
