import gql from "graphql-tag";

export const GET_EXERCISE_VALIDATORS_QUERY = gql`
  query GetExerciseValidatorsQuery {
    getExerciseValidators {
      dragTheWords {
        tag
        min
      }
      fillInTheBlanks {
        tag
        min
        divider
      }
      findSynonym {
        synonym {
          tag
          exact
        }
        real {
          tag
          exact
        }
        fake {
          tag
          min
        }
      }
      findThePlace {
        real {
          tag
          exact
        }
        fake {
          tag
          min
        }
      }
      markTheWords {
        correct
        wrong
        min
      }
      multipleChoice {
        min
      }
      singleChoice {
        min
      }
    }
  }
`;
