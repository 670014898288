import gql from "graphql-tag";

export const GET_USER_ACTIVITY_HISTORY_PAGINATION_QUERY = gql`
  query GetCurrentUserActivityHistories($skip: Int!, $take: Int!) {
    getCurrentUserActivityHistories(
      data: {
        pagination: { skip: $skip, take: $take }
        orderBy: [{ direction: Desc, nulls: Last, fieldName: "created_at" }]
      }
    ) {
      total
      hasMore
      items {
        id
        type
        lesson {
          id
          title
          description
          authorId
        }
        lessonSchedule {
          id
          startsAt
          endsAt
          lessons {
            id
            title
            authorId
            description
          }
          students {
            id
            firstName
            lastName
          }
          lessonBoards {
            id
            title
            fullUri
          }
        }
        quiz {
          id
          title
          description
          authorId
        }
        quizHistory {
          id
          quiz {
            id
            title
            authorId
            description
          }
          maxPoints
          earnedPoints
        }
        createdAt
      }
    }
  }
`;
