import gql from "graphql-tag";

export const GET_QUIZ_QUERY = gql`
  query GetQuizQuery($id: Int!) {
    getQuiz(data: { id: $id }) {
      id
      title
      description
      privacy
      createdAt
      updatedAt
      isVocabulary
      itemsPerPage
      showCorrectnessOnError
      countPartialPts
      authorId
      fork {
        id
        userId
      }
    }
  }
`;
