import gql from "graphql-tag";

export const GET_USER_CONNECTIONS_PAGINATION_QUERY = gql`
  query UserConnectionsPagination(
    $id: Int!
    $search: String
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getUserConnections(
      data: {
        id: $id
        search: $search
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      total
      hasMore
      items {
        user {
          id
          firstName
          lastName
          shortAbout
          profilePhotoX64 {
            id
            photoSize
            fullUri
          }
        }
        connectionStatus
        createdAt
      }
    }
  }
`;
