import gql from "graphql-tag";

export const GET_LESSON_QUIZZES_QUERY = gql`
  query GetLessonQuizzes(
    $lessonId: Int!
    $skip: Int!
    $take: Int!
    $search: String
    $isVocabulary: Boolean
    $orderBy: [OrderByInput!]
  ) {
    getLessonQuizzes(
      data: {
        lessonId: $lessonId
        orderBy: $orderBy
        pagination: { skip: $skip, take: $take }
        search: $search
        isVocabulary: $isVocabulary
      }
    ) {
      hasMore
      total
      items {
        id
        title
        createdAt
        isVocabulary
        fork {
          id
          userId
        }
        privacy
        author {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
      }
    }
  }
`;
