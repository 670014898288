import gql from "graphql-tag";

export const CREATE_LESSON_ATTACHMENT_MUTATION = gql`
  mutation CreateLessonAttachment(
    $file: Upload!
    $lessonId: Int!
    $title: String!
    $order: Int!
  ) {
    createLessonAttachment(
      data: { file: $file, lessonId: $lessonId, title: $title, order: $order }
    ) {
      id
      fullUri
      title
      mimeType
      order
    }
  }
`;
