import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_LESSON_QUERY } from "@/graphql/queries/lesson/get-lesson.query";
import { GET_LESSONS_PAGINATION_QUERY } from "@/graphql/queries/lesson/get-lessons-pagination.query";
import { DELETE_LESSON_MUTATION } from "@/graphql/mutations/lesson/delete.mutation";
import { toSnakeCase } from "@/utils/string";
import { CREATE_LESSON_MUTATION } from "@/graphql/mutations/lesson/create.mutation";
import { UPDATE_LESSON_MUTATION } from "@/graphql/mutations/lesson/update.mutation";
import { CREATE_LESSON_ATTACHMENT_MUTATION } from "@/graphql/mutations/lesson/create-attachment.mutation";
import { DELETE_LESSON_ATTACHMENT_MUTATION } from "@/graphql/mutations/lesson/delete-attachment.mutation";
import { CHANGE_ORDER_LESSON_ATTACHMENT_MUTATION } from "@/graphql/mutations/lesson/change-order-attachment.mutation";
import { UPDATE_LESSON_ATTACHMENT_MUTATION } from "@/graphql/mutations/lesson/update-attachment.mutation";

const lesson = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createLesson(_, { title, description, privacy, notes }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_LESSON_MUTATION,
          variables: {
            title,
            description,
            privacy,
            notes,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async updateLesson(_, { id, title, description, privacy, notes }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UPDATE_LESSON_MUTATION,
          variables: {
            id,
            title,
            description,
            privacy,
            notes,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getLessonQuery(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_LESSON_QUERY,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getLessonsQuery(
      _,
      { skip, take, orderBy, privacy, search, type, userId }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));

      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_LESSONS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            privacy,
            search,
            type,
            userId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async deleteLesson(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: DELETE_LESSON_MUTATION,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async createLessonAttachment(_, { title, file, lessonId, order }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_LESSON_ATTACHMENT_MUTATION,
          variables: {
            title,
            file,
            lessonId,
            order,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async deleteLessonAttachment(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: DELETE_LESSON_ATTACHMENT_MUTATION,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async changeOrderLessonAttachment(_, { first, second }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CHANGE_ORDER_LESSON_ATTACHMENT_MUTATION,
          variables: { first, second },
          fetchPolicy: "no-cache",
        });
      });
    },
    async updateLessonAttachment(_, { id, title }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UPDATE_LESSON_ATTACHMENT_MUTATION,
          variables: { id, title },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default lesson;
