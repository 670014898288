import gql from "graphql-tag";

export const CHECK_QUIZ_EXERCISES_MUTATION = gql`
  mutation CheckQuizExercises(
    $data: [CheckExerciseTypeInput!]!
    $quizId: Int!
    $nonRegisteredData: CheckExercisesNonRegisteredDataInput
  ) {
    checkQuizExercises(
      data: {
        data: $data
        quizId: $quizId
        nonRegisteredData: $nonRegisteredData
      }
    ) {
      id
    }
  }
`;
