import gql from "graphql-tag";

export const CREATE_LESSON_MUTATION = gql`
  mutation CreateLesson(
    $description: String!
    $privacy: LessonQuizPrivacy!
    $title: String!
    $notes: String
  ) {
    createLesson(
      data: {
        description: $description
        privacy: $privacy
        title: $title
        notes: $notes
      }
    ) {
      id
      title
      description
      privacy
      createdAt
      notes
      authorId
      attachments {
        id
        fullUri
        title
        mimeType
        order
      }
    }
  }
`;
