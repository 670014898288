import gql from "graphql-tag";

export const REMOVE_EXERCISE_AUDIO_MUTATION = gql`
  mutation RemoveExerciseAudio($id: Int!) {
    removeExerciseAudio(data: { id: $id }) {
      id
      fullUri
      title
    }
  }
`;
