import gql from "graphql-tag";

export const UPDATE_QUIZ_MUTATION = gql`
  mutation UpdateQuiz(
    $id: Int!
    $description: String!
    $privacy: LessonQuizPrivacy!
    $title: String!
    $itemsPerPage: Int
    $showCorrectnessOnError: Boolean
    $countPartialPts: Boolean
  ) {
    updateQuiz(
      data: {
        id: $id
        description: $description
        privacy: $privacy
        title: $title
        itemsPerPage: $itemsPerPage
        showCorrectnessOnError: $showCorrectnessOnError
        countPartialPts: $countPartialPts
      }
    ) {
      id
      title
      description
      privacy
      createdAt
      updatedAt
      isVocabulary
      itemsPerPage
      showCorrectnessOnError
      countPartialPts
      authorId
      fork {
        id
        userId
      }
    }
  }
`;
