import gql from "graphql-tag";

export const GET_EXERCISES_PAGINATION_QUERY = gql`
  query GetExercisesPagination($skip: Int!, $take: Int!, $quizId: Int!) {
    getExercisesPaginated(
      data: {
        orderBy: [{ direction: Asc, fieldName: "order" }]
        pagination: { skip: $skip, take: $take }
        quizId: $quizId
      }
    ) {
      hasMore
      total
      items {
        id
        title
        description
        createdAt
        errorHint
        isVocabulary
        order
        points
        type
        updatedAt
        version
        quizId
        audioHidden
        audioAutoplay
        audio {
          id
          fullUri
          title
        }
        dragTheWords {
          data
          images
          text
          audios
        }
        fillInTheBlanks {
          images
          text
          audios
        }
        findSynonym {
          text
        }
        findThePlace {
          data
          text
        }
        markTheWords {
          text
        }
        multipleChoice {
          data
        }
        singleChoice {
          data
        }
      }
    }
  }
`;
