import gql from "graphql-tag";

export const GET_CHAT_LIST_PAGINATION_QUERY = gql`
  query GetChats(
    $search: String
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getChatsPaginated(
      data: {
        search: $search
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      hasMore
      total
      items {
        id
        createdAt
        updatedAt
        lessonScheduleId
        lessonSchedule {
          id
          title
          startsAt
        }
        users {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        unreadMessagesCount
        latestMessage {
          id
          userId
          message
          createdAt
        }
      }
    }
  }
`;
