import gql from "graphql-tag";

export const GET_QUIZ_ALL_POINTS_QUERY = gql`
  query GetQuizAllPointsQuery($id: Float!) {
    getQuizAllPoints(id: $id) {
      exercises {
        points
        count
      }
      vocabulary {
        points
        count
      }
      order
    }
  }
`;
