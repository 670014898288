import gql from "graphql-tag";

export const UPDATE_AUDIO_MUTATION = gql`
  mutation UpdateAudio($id: Int!, $title: String!) {
    updateAudio(data: { id: $id, title: $title }) {
      id
      fullUri
      title
    }
  }
`;
