import gql from "graphql-tag";

export const CONFIRM_RESET_USER_PASSWORD_MUTATION = gql`
  mutation ConfirmResetUserPasswordTokenMutation(
    $token: String!
    $code: String!
    $password: String!
  ) {
    confirmResetUserPassword(
      data: { token: $token, code: $code, password: $password }
    )
  }
`;
