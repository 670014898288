import gql from "graphql-tag";

export const GET_EXERCISES_ADMIN_PAGINATION_QUERY = gql`
  query GetExercisesAdminPagination(
    $skip: Int!
    $take: Int!
    $quizId: Int!
    $isVocabulary: Boolean!
    $search: String
  ) {
    getExercisesAdminPaginated(
      data: {
        orderBy: [{ direction: Asc, fieldName: "order" }]
        pagination: { skip: $skip, take: $take }
        quizId: $quizId
        search: $search
        isVocabulary: $isVocabulary
      }
    ) {
      hasMore
      total
      items {
        id
        title
        description
        createdAt
        errorHint
        isVocabulary
        order
        points
        type
        updatedAt
        version
      }
      extraData {
        before {
          id
          title
          order
        }
        after {
          id
          title
          order
        }
      }
    }
  }
`;
