import gql from "graphql-tag";

export const GET_EXERCISE_QUERY = gql`
  query GetExerciseQuery($id: Int!) {
    getExercise(data: { id: $id }) {
      id
      title
      description
      createdAt
      errorHint
      isVocabulary
      order
      points
      type
      updatedAt
      version
      quizId
      audioAutoplay
      audioHidden
      audio {
        id
        fullUri
        title
      }
      dragTheWords {
        answer
        data
        images
        text
        audios
      }
      fillInTheBlanks {
        answer
        images
        text
        audios
      }
      findSynonym {
        answer
        text
      }
      findThePlace {
        answer
        data
        text
      }
      markTheWords {
        answer
        text
      }
      multipleChoice {
        answer
        data
      }
      singleChoice {
        answer
        data
      }
      trueFalse {
        answer
      }
    }
  }
`;
