import gql from "graphql-tag";

export const RENEW_EXERCISE_AUDIO_MUTATION = gql`
  mutation RenewExerciseAudio(
    $newAudio: CreateAudioInput!
    $oldAudio: RemoveAudioInput!
  ) {
    renewExerciseAudio(data: { newAudio: $newAudio, oldAudio: $oldAudio }) {
      id
      fullUri
      title
    }
  }
`;
